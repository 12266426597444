html {
  overflow-y: auto;
  overflow-x: hidden;
}
body,
header {
  padding-right: 0 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px #212121 inset !important;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}

.MuiTableCell-sizeSmall {
  padding: 6px 8px 6px 8px !important;
}

.MuiAutocomplete-inputRoot[class*='MuiFilledInput-root']
  .MuiAutocomplete-input {
  padding-top: 3px !important;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
  .MuiTableSortLabel-icon {
  color: inherit !important;
  opacity: 1;
}
.MuiTableSortLabel-root.MuiTableSortLabel-active {
  color: inherit !important;
}
.MuiTableSortLabel-root:hover {
  color: inherit !important;
}

@media (min-width: 1280px) {
  .MuiContainer-maxWidthLg {
    max-width: 100% !important;
  }
}

@media (pointer: coarse) {
  html {
    margin: 0;
    height: 100%;
    overflow: hidden;
  }

  body {
    margin: 0;
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  select,
  textarea,
  input {
    font-size: 16px !important;
  }

  .MuiBox-root {
    padding: 12px !important;
  }

  .MuiDialog-paper {
    margin: 12px !important;
  }

  .MuiDialog-paperScrollBody {
    max-width: calc(100% - 24px) !important;
  }
  .MuiDialog-paperFullWidth {
    width: calc(100% - 14px) !important;
  }
}

.MTableHeader-header-942 {
  width: auto !important;
}

@media (min-width: 1200px) {
  .MuiContainer-maxWidthMd {
    max-width: 1200px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

ul {
  padding-left: 15px;
}

input:disabled {
  background-color: #ddd;
}

.MuiDialog-paperFullScreen {
  margin: 0 !important;
}

li {
  margin-bottom: 0px;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  outline: none;
  border: 4px solid #666;
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 50px;
  color: #666;
  width: auto;
}

.image-gallery-svg {
  color: #666;
  filter: none;
}

.image-gallery-icon {
  filter: none;
}

.MuiGrid-spacing-xs-8 {
  width: 100% !important;
  margin: 0 !important;
}

.MuiGrid-spacing-xs-8 > .MuiGrid-item {
  padding: 0 !important;
}
input[type='file'] {
  width: 100% !important;
}
.MuiPaper-elevation4 {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

.App {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
}

.controls {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 80px;
  display: flex;
  align-items: center;
}

.slider {
  padding: 22px 0px;
}
